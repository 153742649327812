
export default {
    methods: {
        set: function(creditVisible) {
            var self = this;
            this.creditVisible = !creditVisible;
            return this.$emit('update:credit-button', this.creditVisible);
        }
    },
    props: {
        city: Object
    },
    data() {
        return {
            creditVisible: false,

            // Src Set
            coverSrcSet: (this.city.cover_srcset.length) ? this.city.cover_srcset[0] : this.city.region.cover_srcset[0],
        }
    },
}
