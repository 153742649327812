import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1dba05e7&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderHome: require('/src/components/HeaderHome.vue').default,BigFranceMap: require('/src/components/BigFranceMap.vue').default,GoogleAds: require('/src/components/GoogleAds.vue').default,BaseStars: require('/src/components/BaseStars.vue').default,MainContainer: require('/src/components/MainContainer.vue').default})
