
const range = (start, end, length = end - start + 1) =>
    Array.from({ length }, (_, i) => start + i)

export default {
    props: {
        value: Number,
        total: Number
    },
    data () {
        return {
            completeRange: range(1, this.value ),
            halfRange: (this.value % 1 === 0 ? 0 : 1),
            totalRange: range(1, this.total-Math.ceil(this.value))
        }
    },
    created: function () {
        if (this.value > this.total) {
            throw new Error('total lower than value')
        }
    }
}
