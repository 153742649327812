
export default {
    async asyncData({ $config: { baseApiURL }, $axios }) {
        const props = await $axios.$get(`${baseApiURL}/home/`);
        return {
            subRegions: props.subRegions,
            regions: props.regions,
            cities: props.cities,
            reviews: props.reviews,
            featuredCity: props.featuredCity
        }
    }
}
