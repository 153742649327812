
import FranceMap from "~/assets/svg/france.svg?inline";

export default {
    components: {
        FranceMap,
    },
    props: {
        subRegions: Array
    },
    data() {
        return {
            tpDisplay: 'none',
            tpLeft: 'none',
            tpTop: '-1000px',
            tpMainText: '',
            tpRegion: ''
        }
    },
    methods: {
        showInfoCard: function (e) {
            this.tpDisplay = 'block';
            this.tpLeft = e.offsetX + 20 + 'px';
            this.tpTop = e.offsetY + 20 + 'px';
            const path = e.path || (e.composedPath && e.composedPath());
            const departementId = path[0].id;
            const subRegion = this.subRegions.find(elmt => elmt.fr_code_dep === departementId);
            this.tpMainText = path[0].id + ' ' + subRegion.name;
            this.tpRegion =
                this.formatNumericValue(subRegion.total_cities) + ' communes' + '<br />'
                + this.formatNumericValue(subRegion.population) + ' habitants';

        },
        leaveInfoCard: function (e) {
            this.tpDisplay = 'none';
            this.tpLeft = 'none';
            this.tpTop = '-1000px';
            this.tpMainText = '';
            this.tpRegion = "";

        },
        redirectToRegion: function (e) {
            const path = e.path || (e.composedPath && e.composedPath());
            const departementId = path[0].id;
            const subRegion = this.subRegions.find(elmt => elmt.fr_code_dep === departementId);
            if ((subRegion.slug == 'paris')) {
                this.$router.push(`/paris-75056/`);
                return;
            }
            this.$router.push({
                name: 'regions-region-departements-departement',
                params: { region: subRegion.parent.slug, departement: subRegion.slug }
            });

        }
    },
    mounted() {
        this.$nextTick(function () {
            let paths = this.$el.querySelectorAll('path');
            paths.forEach(el => {
                el.addEventListener("mouseover", this.showInfoCard);
                el.addEventListener('mouseleave', this.leaveInfoCard);
                el.addEventListener("click", this.redirectToRegion);
            });
        });
    },
}
