
export default {
    props: {
        adSlot: {
            type: String,
            required: true
        },
        adClient: {
            type: String,
            default: 'ca-pub-6732261448177277'
        }
    },
    mounted() {
        this.loadAds();
    },
    beforeRouteUpdate(to, from, next) {
        this.loadAds();
        next();
    },
    methods: {
        loadAds() {
            let attempts = 0;
            const maxAttempts = 5;
            const interval = 100; // Retry interval in milliseconds

            const intervalId = setInterval(() => {
                if (typeof window.adsbygoogle !== 'undefined') {
                    (adsbygoogle = window.adsbygoogle || []).push({});
                    clearInterval(intervalId);
                } else if (attempts >= maxAttempts) {
                    clearInterval(intervalId);
                    console.warn('adsbygoogle script not loaded after multiple attempts');
                }
                attempts++;
            }, interval);
        }
    }
}
